import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import {FormControl, FormGroup, Validators, ReactiveFormsModule, NgForm, NgModel } from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatSnackBar, SimpleSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';


import { ThemePalette } from '@angular/material/core';  
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { tokenize } from '@angular/compiler/src/ml_parser/lexer';
import { environment } from 'src/environments/environment';
import { NONE_TYPE } from '@angular/compiler';

@Component({
  selector: 'app-helferlein',
  templateUrl: './helferlein.component.html',
  styleUrls: ['./helferlein.component.css']
})
export class HelferleinComponent implements OnInit {


  all_campains: string = 'show';
  christmas_campaign = false;
  easter_campaign = false;
  schoolstart_campaign = false;

  prefered_child : string = 'egal';
  send_mail = true;

  @ViewChild('formDirective') private formDirective: NgForm | undefined;

  constructor(private _snackBar: MatSnackBar, private httpClient : HttpClient) {
  }



  color: ThemePalette = "primary";
  contactForm = new FormGroup({
    gender : new FormControl('',[Validators.required]),
    title : new FormControl(),
    firstname : new FormControl('',[Validators.required]),
    lastname : new FormControl('',[Validators.required]),
    title_after : new FormControl(),
    street : new FormControl(''),
    postcode : new FormControl(''),
    city : new FormControl(''),
    email : new FormControl('',[
      Validators.required,
      Validators.email,
    ]),
    telefon : new FormControl('',[Validators.required]),
    message : new FormControl()
  });

  ngOnInit(): void {

  }



  submit() {

    if(this.contactForm.valid)
    {
      console.log("valid form - sending email");
    }
    else{
      console.log("invalid form - try once again");
      return;
    }

    var user_data = this.contactForm.value;
    if(this.all_campains == 'show') // show means the optional menu is shown and user do not want all campains
    {
      user_data['all_campains'] = false
      user_data['christmas_campaign'] = this.christmas_campaign
      user_data['easter_campaign'] = this.easter_campaign
      user_data['schoolstart_campaign'] = this.schoolstart_campaign
    }
    else {
      user_data['all_campains'] = true
      user_data['christmas_campaign'] = true
      user_data['easter_campaign'] = true
      user_data['schoolstart_campaign'] = true
    }

    user_data['prefered_child'] = this.prefered_child;

    this.sendUserData(user_data);

  }


  public sendUserData(user_data :  any){
    // temporary rewrite because we have different naming convensions
  var gender = 'MALE';
  if(user_data['gender'] == 'Frau') gender = 'FEMALE';
  if(user_data['gender'] == 'Frau*Herr') gender = 'ANY';

  var child_gender = 'MALE';
  if(user_data['prefered_child'] == 'Maedchen') child_gender = 'FEMALE';
  if(user_data['prefered_child'] == 'egal') child_gender = 'BOTH';
  var body = {
    "subscriberGender": gender,
    "titlePre": user_data['title'],
    "firstName": user_data['firstname'],
    "lastName": user_data['lastname'],
    "titlePost": user_data['title_after'],
    "street": user_data['street'],
    "zipCode": user_data['postcode'],
    "city": user_data['city'],
    "email" : user_data['email'],
    "phone": user_data['telefon'],
    "optInChristmas": user_data['christmas_campaign'],
    "optInEaster": user_data['easter_campaign'],
    "optInSchool":user_data['schoolstart_campaign'],
    "childGender": child_gender
  }

  const headers = new HttpHeaders({
    "Content-Type": "application/json"
  });

  this.httpClient.post('https://helferlein.wuenschmirwas.at/api/subscribers', body, {'headers' : headers})
    .subscribe(result => {
      this.sendEmail(user_data);
      this.openSnackBar();
    }, 
    (error) => {
      //this.openSnackBarError();
      this.openSnackBar();
      this.sendErrorEmail(user_data, error);
    });

    this.formDirective?.resetForm();
  }


  public sendEmail(email_parameters : any) {

    console.log(email_parameters); 
    if(this.send_mail)
    {
      emailjs.send('service_smtp_sysup', 'template_contactform', email_parameters, 'user_h3RMo1AkVthTZkENPkDbE')
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    }
  }

  public sendErrorEmail(email_parameters : any, error : any) {

    email_parameters['error_name'] = error.name;
    email_parameters['error_message'] = error.message;
    email_parameters['error_url'] = error.url;
    console.log(email_parameters); 
    if(this.send_mail)
    {
      emailjs.send('service_smtp_sysup', 'template_contactform_err', email_parameters, 'user_h3RMo1AkVthTZkENPkDbE')
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    }
  }

  openSnackBar() {
    this._snackBar.openFromComponent(FormConformationComponent, {
      duration: 60 * 1000,
      verticalPosition : "top",

      data : { // requried to have the snackbar to the Component (to close it)
        snackbar : this._snackBar,
      },
    });
  }

  openSnackBarError() {
    this._snackBar.openFromComponent(FormErrorComponent, {
      duration: 30 * 1000,
      verticalPosition : "top"
    });
  }
}

@Component({
  selector: 'snack-bar-component-example-snack',
  templateUrl: 'form_confirmation.html',
  styleUrls: ['./snackbar_style.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FormConformationComponent {

  // give the snackbar as argument to be able to close it inside this component
  constructor(private snackbarRef : MatSnackBarRef<SimpleSnackBar>) {}
  dismiss() {
    console.log("Snackbar closed");
    this.snackbarRef.dismiss()
  }
  
}


@Component({
  selector: 'snack-bar-component-example-snack',
  templateUrl: 'form_error.html',
  styleUrls: ['./snackbar_style_err.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FormErrorComponent {}